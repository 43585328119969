<template>
    <div class="mapWrapper">
        <div class="commonMap" :id="id"></div>
        <div class="tool-box-wrapper" :style="'right:' + dynamicStyle + 'px'">
            <div v-for="(tool, index) in localConfig.tools"
                 :key="index"
                 class="tool-box"
                 @click.stop="toolHandler(tool)"
                 :style="
            (tool === 'hierarchy' && showMore.hierarchy) ||
            (tool === 'command' && showMore.command) ||
            (tool === 'search' && showMore.search) ||
            (tool === 'measure' && showMore.measure) ||
            (tool === 'marker' && showMore.marker) ||
            (tool === 'element' && showMore.element)
              ? 'opacity: 1;'
              : ''
          ">
              <img :src="icons[tool]" alt />
              <div class="more-actions measureBox"
                   v-if="tool === 'element'"
                   v-show="showMore.element">
                <div @click.stop="drawEmlemnt(1)"
                     :style="mouseToolPoint">
                  <span>点</span>
                </div>
                <div @click.stop="drawEmlemnt(2)"
                     :style="mouseToolLine">
                  <span>线</span>
                </div>
                <div @click.stop="drawEmlemnt(3)"
                     :style="mouseToolPoly">
                  <span>面</span>
                </div>
                <div v-if="teamData.length>0" @click.stop="drawEmlemnt(4)"
                     :style="mouseToolPoly">
                  <span>刪除</span>
                </div>
              </div>
            </div>
      </div>
      <div class="teamBox" v-show="showMore.deleteList" >
        <h3>点线面列表</h3>
        <div class="teamItem" v-for="(item,index) in teamData" :key="index">
          <span>{{index+1}}{{item.name}}</span>
          <Icon @click="deleteElement(item.id)"  color="i" type="md-trash" />
        </div>
      </div>
      <!--搜索框-->
      <div class="searchBox" v-show="showMore.search">
        <i-input v-model="searchMsg" autosize prefix="ios-search" placeholder="搜索或输入您的地点" @keyup.enter.native="search" />
      </div>
        <!--发送目标点-->
        <div class="sendCommander"
             v-show="showMore.markerModal"
             v-if="config.onLineList"
             @click.stop="">
            <div class="top">任务标记</div>
            <div class="no-data"
                 v-if="!config.onLineList.length">
                暂时没有无人机在线!
            </div>
            <div class="coordinate">
                <p>地理位置</p>
                经度 <span>{{ sendCommanderOptions.local_data[0].lng }}</span><br />
                纬度
                <span>{{ sendCommanderOptions.local_data[0].lat }}</span>
            </div>
            <div class="mark_info coordinate">
                <p>标注信息</p>
                <div>名称<input v-model="targetName" /></div>
                <div>
                    <label>备注</label>
                    <textarea v-model="targetRemarks" />
                </div>
            </div>
            <div class="mark_style coordinate">
                <p>标注样式</p>
                <div>
                    图标
                    <div style="
              background: #dbdbdb;
              display: inline-block;
              padding: 2px;
              vertical-align: middle;
              margin: 0 20px 0 8px;
              border-radius: 2px;
            ">
                        <img :src="targetIcon"
                             alt="" />
                    </div>
                    <button @click="changeType = !changeType">更换</button>
                    <ul v-show="changeType"
                        class="icons"
                        @click.stop="">
                        <li>
                            <img :src="navigation"
                                 alt="navigation"
                                 @click.stop="targetType(navigation, 'mark_state_navigation')" />
                        </li>
                        <li>
                            <img :src="doubt"
                                 alt="doubt"
                                 @click.stop="targetType(doubt, 'mark_state_doubt')" />
                        </li>
                        <li>
                            <img :src="set"
                                 alt="set"
                                 @click.stop="targetType(set, 'mark_state_set')" />
                        </li>
                        <li>
                            <img :src="prohibit"
                                 alt="prohibit"
                                 @click.stop="targetType(prohibit, 'mark_state_prohibit')" />
                        </li>
                        <li>
                            <img :src="warning"
                                 alt="warning"
                                 @click.stop="targetType(warning, 'mark_state_warning')" />
                        </li>
                    </ul>
                </div>
                <div>
                    <button class="sendBtn"
                            :loading="sendCommanderOptions.loading"
                            @click.stop="submitSendCommander">
                        确定
                    </button>
                    <button class="sendBtn sendCancel"
                            @click.stop="cancelSendCommander">
                        取消
                    </button>
                </div>
            </div>
        </div>
        <Modal v-model="addDxm" :title="isUpdate?'修改':'添加'" @on-cancel="addDxmNameClose" :mask-closable="false">
            <Input v-model="addDxmName" maxlength="16" placeholder="请输入" style="width: 300px;margin-left:80px;" />
           <div class="color-box">
               <label>请你选择颜色: <span :style="'background:'+ colorListNum"></span></label>
               <ul>
                   <li v-for="(item,index) in colorList" :key="index" :class="{active:index==colorListNum}" @click="colorListBut(item)" :style="'background:'+ item"></li>
               </ul>
           </div>
           <template  slot="footer">
             <Button   @click="addDxmNameClose" shape="circle">取消</Button>

             <Button type="primary" v-if="isUpdate"  @click="UpdateElement"  shape="circle">修改</Button>
             <Button type="primary"  v-else @click="sendElement"  shape="circle">新增</Button>
           </template>
      </Modal>
    </div>
</template>

<script>
let mapId = 0;
import _ from 'lodash';
import axios from 'axios';
import search from '@/assets/img/Icon/search.png';
import measure from '@/assets/img/Icon/measure.png';
import hierarchy from '@/assets/img/Icon/hierarchy.png';
import zoomIn from '@/assets/img/Icon/jia.png';
import zoomOut from '@/assets/img/Icon/jian.png';
import fix from '@/assets/img/Icon/fix.png';
import marker from '@/assets/img/Icon/marker.png';
import command from '@/assets/img/Icon/command.png';
import avatar from '@/assets/img/statistics/ava1.png';
import sendMarker from '@/assets/img/Marker/Level_2_project.png';
import element from '@/assets/img/Icon/target_point.png';
import PlaneGreen from '@/assets/img/Marker/drone-green.png';
import PlaneRed from '@/assets/img/Marker/drone-red.png';
import PlaneYellow from '@/assets/img/Marker/drone-yellow.png';
import PlaneBlue from '@/assets/img/Marker/drone-blue.png';
import PlanePurple from '@/assets/img/Marker/drone-purple.png';
import Drone from '@/assets/img/Marker/drone.png';
import uav from '@/assets/img/Marker/uav.png';
import cpRPAs from '@/utils/cpRPA.mixin.js';
import Api from '@/utils/api.js';
import navigation from '@/assets/img/Icon/mark_navigation.png';
import doubt from '@/assets/img/Icon/mark_doubt.png';
import set from '@/assets/img/Icon/mark_set.png';
import prohibit from '@/assets/img/Icon/mark_prohibit.png';
import warning from '@/assets/img/Icon/mark_warning.png';
import droneHeight from '@/assets/img/Icon/drone_hight.png';
import droneSpeed from '@/assets/img/Icon/drone_speed.png';
import droneDirection from '@/assets/img/Icon/drone_direction.png';
import dronePosition from '@/assets/img/Icon/drone_position.png';
import initAdderss from '@/assets/img/Marker/pic_red.png';
import tp from '@/assets/img/Icon/target_point.png';
import iocnAdderss from '@/assets/img/shangyun/mark-itme.png';
import DXcursorImg from '@/assets/img/shangyun/cursorImg.png';
import coordinateTransform from '../../../../public/lib/coordinateTransform.js';
import useradderss from '@/assets/img/shangyun/wrj.png';
import gateway from '@/assets/img/shangyun/gateway.png';
    export default {
        props: {
            config: {
                type: Object,
                required: false,
                default: () => {},
            },
            mapFlyingParams: {
                type: Object,
                required: false,
                default: () => {},
            },
            rapidInfo: {
                type: Object,
                default: () => {},
            },
            targetPoint: {
                type: Object,
                required: false,
                default: () => {},
            },
        },
        name: "map",
        mixins: [cpRPAs],
        data(){
            return{
              teamData:[],
                mapType:'2d',
                map:'', //启动高德地图点线面
                searchMsg:'',//搜索
                showMore: {
                  hierarchy: false,
                  search: false, //显示搜索
                  measure: false,
                  marker: false,
                  markerModal: false,
                  command: false,
                  element: false,
                  deleteList:false
                },
                device:'',//设备
                showName: 'visible', // 显示名称
                localConfig: {
                  toolsMovingDistance: 0, // 右边有浮层 工具条定位需要改变的移动距离
                  // search: 搜索 measure: 尺子 hierarchy: 图层 zoomIn: 放大 zoomOut: 缩小
                  tools: ['hierarchy', 'zoomIn', 'zoomOut'],

                },
                sendCommanderOptions: {
                  selectUids: [],
                  loading: false,
                  local_data: [
                    {
                      lat: 0,
                      lng: 0,
                      height: 120,
                    },
                  ],
                  markerType: 'mark_state_navigation', // 目标点类型
                  type: 3,
                },
                UAVSelectedList: [],
              targetName: '新增目标点', // 标注点名称
              targetRemarks: '', // 标注点备注
              sendMarker: '', // 地图当前目标点集合
              targetIcon: navigation, // 目标点图标
                changeType: false,
                navigation,
                doubt,
                set,
                prohibit,
                warning,
                mapLayer: '平面',
              mapArea: ['禁飞区'],
              measureType: 'line', // area
              mouseTool:'',// 注册高德测量
              satelliteLayer:'',  //卫星图
              roadNetLayer:'',// 路网图
              workspaceID:'',//
              icons: {
                 search,
                 measure,
                 hierarchy,
                 zoomIn,
                 zoomOut,
                 fix,
                 marker,
                 sendMarker,
                 avatar,
                 PlaneGreen,
                 PlaneRed,
                 PlaneYellow,
                 PlaneBlue,
                 PlanePurple,
                 Drone,
                 command,
                 droneHeight,
                 droneSpeed,
                 droneDirection,
                 dronePosition,
                 element,
             },
              userAdderssList:[],
              websock: null,
              addDxm:false,
              addDxmName:'',//点线面名称
              nowObj:'',
              numMarker:true,
              mapList:[], // 点线面集合
              colorList:['#2D8CF0','#19BE6B','#FFBB00','#B620E0', '#E23C39','#212121'],
              colorListNum:'#2D8CF0',
              MarkerMapShow:'',
              isUpdate:false,
              updataElementData:{},
              updateid:'',
              dynamicStyle:20,
            }
        },
        methods:{
            // 连接Websocket
            InitWebsocket() {
                const wsuri = Api.getSY_WebSocketUrl() + '?x-auth-token=' + sessionStorage.getItem('sy_token');
                this.websock = new WebSocket(wsuri);
              //  console.log('连接成功777777777777',this.websock);

                // console.log("webcock 实例对象", this.websock);
                this.websock.onmessage = this.websocketonmessage;
                this.websock.onopen = this.websocketonopen;
                this.websock.onerror = this.websocketonerror;
                this.websock.onclose = this.websocketclose;
            },
            websocketonopen() {
                // 建立连接
                console.log('开启websock成功');
                let actions = { test: '12345' };
                this.websocksend(JSON.stringify(actions));
            },
            websocketonmessage(webevent) { //获取值
               let res= JSON.parse(webevent.data)

          //    console.log('res',res)

              if(res.biz_code==="map_element_create" || res.biz_code==="map_element_update"){
                //添加
                this.getElementGroups()
              }
              if( res.biz_code==="map_element_update"){
                //添加
                this.map.remove(this.mapList)
                this.getElementGroups()
              }



              if(res.biz_code==="map_element_delete"){
                //删除
                this.map.remove(this.mapList)
                this.getElementGroups()
              }
              if(res.biz_code==="gateway_osd"){  //
                if(res.data.longitude!==0 & res.data.latitude !==0){
                  if(this.numMarker){
                    let IPlocation= wgs84togcj02(res.data.longitude,res.data.latitude)
                    this.sendMarker = new AMap.Marker({
                      map:this.map,
                      autoRotation: true,
                      showDir: true,
                      angle:0,
                      offset: new AMap.Pixel(-30, -30),
                      // position: new AMap.LngLat(dateList[0], dateList[1]),
                      position:IPlocation,
                      icon: useradderss, // 添加 Icon 图标 URL
                    });
                    this.userGateway= new AMap.Marker({
                      map:this.map,
                      autoRotation: true,
                      showDir: true,
                      angle:0,
                      offset: new AMap.Pixel(-20, -20),
                      position:IPlocation,
                      icon:gateway, // 添加 Icon 图标 URL
                      label: {
                        direction: 'top',
                        content:` 飞 手 `,
                      }
                    });
                    this.numMarker =false
                  }
                  let IPlocation= wgs84togcj02(res.data.longitude,res.data.latitude)
                  this.userGateway.setPosition(IPlocation); //更新点标记位置
                }
              }
            //  console.log('无人机位置++++++++++++++',res.biz_code,res.data.longitude,res.data.latitude)
              if(res.biz_code==="device_osd"){  //无人机设备
              //  console.log('211221121121221121212',res.data)
                if(res.data.longitude!==0 & res.data.latitude !==0){
                  let IPlocation= wgs84togcj02(res.data.longitude,res.data.latitude)

                  //设置方向
                  this.sendMarker.setAngle(Number(res.data.attitude_head));

                  this.sendMarker.setPosition(IPlocation); //更新点标记位置
                }
              }


            },

            websocketonerror() {
                // 错误信息
               // console.log('websock 重连1111111');
                this.InitWebsocket();
            },
            websocketclose() {
                this.websock.close()
                this.websock=null
                // websocket被关闭
                console.log(' 关闭 websock');
            },
            websocksend(data) {
                 console.log('websockslllllllllllllllll',data);
                this.websock.send(data);
            },
            colorListBut(item){
              this.colorListNum=item
            },
            initMap(){   // 地图初始化
              if (this.mapType === '2d') {
                this.map = new AMap.Map(this.id, {
                  resizeEnable: true, //是否监控地图容器尺寸变化
                  zoom: 14, //初始化地图层级
                  center: [112.97935279, 28.21347823], //初始化地图中心点
                 // center: this.$Center, //初始化地图中心点
                  // 是否使用高精度定位，默认：true
                  enableHighAccuracy: true

                });
              }else {
                  this.map = new AMap.Map(this.id, {
                  pitch: 75, // 地图俯仰角度，有效范围 0 度- 83 度
                  viewMode: '3D', // 开启3D视图,默认为关闭
                  height:100,
                  resizeEnable: true, //是否监控地图容器尺寸变化
                  zoom: 14, //初始化地图层级
                  center: [112.97935279, 28.21347823], //初始化地图中心点
                });
              }

              this.mouseTool = new AMap.MouseTool(this.map);
              //卫星地图
              this.satelliteLayer = new AMap.TileLayer.Satellite();

              this.map.add(this.satelliteLayer);
              // 减少名称
              var features = ['bg', 'road', 'building'];

              this.map.setFeatures(features);

            //  this.map.remove([this.sendMarker])
              },
            getAdderss(path){
               let dateList= wgs84togcj02(path[0],path[1])
               this.sendMarker = new AMap.Marker({
                 map:this.sendMarker,
                // position: new AMap.LngLat(dateList[0], dateList[1]),
                 position:[112.97935279, 28.21347823],
                 icon: useradderss, // 添加 Icon 图标 URL
                 offset: new AMap.Pixel(-15, -15),
               });
               this.sendMarker.setMap(this.map);
               this.sendMarker.setMap(null);
             },
            // 更换标记点类型
            targetType(type, typeStr) {
                this.targetIcon = type;
                this.changeType = false;
                if (this.sendMarker) {
                    this.sendMarker.setIcon(this.targetIcon);
                }
                this.sendCommanderOptions.markerType = typeStr;
            },
            // 初始化轨迹
            initPolylinePath() {
              let config = this.mapFlyingParams;
              if (!this.drawMarker) {
                this.drawMarker = new AMap.Marker({
                  map: this.map,
                  position: [config.pathArr[0][0], config.pathArr[0][1]],
                  icon: uav,
                  offset: new AMap.Pixel(-26, -25),
                  autoRotation: true,
                  angle: -90,
                });
              }

              // 绘制轨迹
              if (!this.drawPolyline) {
                let fullPath = _.cloneDeep(config.pathArr);
                this.drawPolyline = new AMap.Polyline({
                  map: this.map,
                  path: fullPath,
                  showDir: true,
                  strokeColor: '#28F', //线颜色
                  // strokeOpacity: 1,     //线透明度
                  strokeWeight: 6, //线宽
                  // strokeStyle: "solid"     //线样式
                });
              }

              if (!this.passedPolyline) {
                this.passedPolyline = new AMap.Polyline({
                  map: this.map,
                  // path: lineArr,
                  strokeColor: '#AF5', //线颜色
                  // strokeOpacity: 1,     //线透明度
                  strokeWeight: 6, //线宽
                  // strokeStyle: "solid"  //线样式
                });
              }

              this.map.setFitView();
            },
            toolHandler(type) {  //
                console.log('这是什么:',type);
                // 指点飞行和群体飞控禁止其他地图操作
                if (this.showMore.marker) {
                    if (type != 'marker') {
                        return;
                    }
                } else if (this.showMore.command) {
                    if (type != 'command') {
                        return;
                    }
                }

                if (type === 'marker') { // 标点
                    this.showMore.marker = !this.showMore.marker;
                    this.targetName = '新增目标点';
                    // 显隐左侧标记工具栏，打开地图addmark
                    if (!this.showMore.markerModal) {
                        this.showMore.markerModal = true;
                    } else {
                        // 关闭左侧标记工具栏，清除
                        this.showMore.markerModal = false;
                        if (this.sendMarker) {
                            this.map.remove(this.sendMarker);
                            this.sendMarker = null;
                            // this.sendMarkers.pop();
                        }
                    }

                    this.toggleMarkerClickOrDragEvent(this.showMore.marker);
                    return;
                }
                if (type === 'search') {  //搜索
                    this.showMore.search = !this.showMore.search;
                }
                if (type === 'zoomIn') {
                    // 放大
                    this.map.zoomIn();
                }
                if (type === 'zoomOut') {
                    // 放小
                    this.map.zoomOut();
                }
                if (type === 'element') {
                    // 点线面
                    this.showMore.element = !this.showMore.element;
                }
            },
            search(){  //搜索位置
              if (!this.searchMsg) {
                this.map.setCenter([112.97935279, 28.21347823]);
                return;
              }
              let _this = this;
              // 获取地名转化成纬度
              var geocoder = new AMap.Geocoder({
                radius: 1000,
                extensions: "all",
                strokeColor:'#1791fc', //线颜色
                fillColor:'#1791fc',
              });  //搜索地名
              console.log('searchMsg',this.searchMsg)
              console.log('geocoder',geocoder)
              let address = this.searchMsg;
              geocoder.getLocation(address,function (status, result){
                if (status === 'complete' && result.geocodes.length) {
                  let lnglat=result.geocodes[0].location;
                 // console.log("lnglat",lnglat);
                 _this.map.setCenter([lnglat.lng, lnglat.lat]); //设置地图中心点
                  //标记圆形位置
                    // 搜索后图片显示
                _this.sendMarker = new AMap.Marker({
                    position: new AMap.LngLat(lnglat.lng, lnglat.lat),
                    icon: initAdderss, // 添加 Icon 图标 URL
                    offset: new AMap.Pixel(-15, -15),
                });

                _this.map.add(_this.sendMarker);
                }else {
                  this.$Message.error('根据地址查询位置失败');
                }
              })
            },
            ShouBuildFigure(){ //显示建图任务区域
              let _this = this;
              this.map.clearMap(); //关闭点位地图
              let Data = JSON.parse(sessionStorage.getItem('getTaskData'));
              console.log('11111111111',Data)
              let local_data = Data.local_data;
              var path = [];
              local_data.forEach((item) => {
                path.push([item.Lng, item.Lat]);
              });
              // 画多边形
              var polygon = new AMap.Polygon({
                path: path,
                strokeColor: '#FF33FF',
                strokeWeight: 6,
                strokeOpacity: 0.2,
                fillOpacity: 0.4,
                fillColor: '#1791fc',
                zIndex: 50,
              });
              polygon.DATA = Data;
              this.map.add(polygon);
              // 缩放地图到合适的视野级别
              this.map.setFitView([polygon]);

            },
            // 设置目标点
            toggleMarkerClickOrDragEvent(e) {
                this.canExecEvent = e;
                this.map.off('click', this.clickMap);
                if (e) {
                    this.map.on('click', this.clickMap);
                }
            },
            // 点击地图事件
            clickMap(event) {
                console.log('点击地图事件1121121121',event)
                let { lng, lat } = event.lnglat;
                // let { x, y } = event.pixel;
                if (this.showMore.marker) {
                    this.toggleMarkerClickOrDragEvent(true);
                    this.sendCommanderOptions.markerType = 'mark_state_navigation';
                    // this.sendMarker = null;
                    this.targetRemarks = '';
                    this.addMarkerToSendCommander([lng, lat]);
                } else {
                   // this.drawLineOrArea([[lng, lat]]);
                }
            },
            // 添加指挥官目标点
            addMarkerToSendCommander(position) {
                if (this.sendMarker) {
                    this.map.remove(this.sendMarker);
                }
                // this.toggleeMapDrag(false);
                this.sendCommanderOptions.local_data[0].lat = position[1];
                this.sendCommanderOptions.local_data[0].lng = position[0];
                //点击显示图片
                this.sendMarker = new AMap.Marker({
                    position: new AMap.LngLat(position[0], position[1]),
                    icon: this.targetIcon, // 添加 Icon 图标 URL
                    offset: new AMap.Pixel(-15, -15),
                });
                this.map.add(this.sendMarker);
                this.showMore.markerModal = true;
            },
            // 取消指挥官目标点
            cancelSendCommander() {
                this.showMore.markerModal = false;
                this.showMore.marker = false;
                if (this.sendMarker) {
                    this.map.remove(this.sendMarker);
                    this.sendMarker = '';
                    // this.sendMarkers.pop();
                }
                this.resetSendCommanderOptions();
                this.toggleMarkerClickOrDragEvent(false);
            },
            // 绘制点线面
            drawEmlemnt(type) {
                console.log('this.groundId', type);
                this.mouseTool.on('draw', this.getDraw);
                switch (type) {
                    case 1: // 点
                        this.elementType = 'pin';
                        this.mouseTool.marker({
                            icon: new AMap.Icon({
                                size: new AMap.Size(24, 24), // 图标尺寸
                                image: require('@/assets/img/shangyun/pin.svg'), // Icon的图像
                                imageSize: new AMap.Size(24, 24), // 根据所设置的大小拉伸或压缩图片
                            }),
                            offset: new AMap.Pixel(-12, -12),
                            extData: { type: 1 },
                        });
                        break;
                    case 2: // 线
                        this.elementType = 'line';
                        this.mouseTool.polyline({
                            strokeColor: '#19BE6B',
                            strokeWeight: 2, //线宽
                            extData: { type: 2 },
                        });
                        break;
                    case 3: // 面
                        this.elementType = 'poly';
                        this.mouseTool.polygon({
                            fillColor: '#19BE6B',
                            strokeColor: '#19BE6B',
                            extData: { type: 3 },
                        });
                        break;
                     case 4:
                           if(this.teamData.length==0){
                             this.$Message.info('暂无点线面列表');
                             this.dynamicStyle=20
                             return
                           }
                          this.showMore.deleteList= !this.showMore.deleteList
                          if(this.showMore.deleteList){
                            this.dynamicStyle=220
                          }else {
                            this.dynamicStyle=20
                          }


                       break;
                    default:
                        break;
                }
                console.log('this.mouseTool',this.mouseTool)
            },
          // 取消点线面提交
            addDxmNameClose(){
              this.map.remove(this.nowObj);
              this.addDxmName=''
              this.addDxm=false
              this.isUpdate=false
              this.updateid=''
              this.colorListNum='#2D8CF0'
            },
            // 绘制完成后获取
            getDraw(e) {
                console.log('111111111111',e)

                 this.nowObj = e.obj;  //获取当前点击的事件
                this.elementType = '';
                this.sendElementData = {};
                this.mouseTool.close();
                let path = [];
                switch (e.obj.getExtData().type) {
                    case 1:
                        this.sendElementData = {
                            id: this.uuidv4(),
                            name: '点',
                            resource: {
                                type: 0,
                                user_name: sessionStorage.getItem('user_name'),
                                content: {
                                    type: 'Feature',
                                    properties: {
                                        color: '#2D8CF0',
                                        clampToGround: true,
                                    },
                                    geometry: {
                                        type: 'Point',
                                        coordinates: gcj02towgs84(
                                            e.obj.getPosition().lng,
                                            e.obj.getPosition().lat
                                        ),
                                    },
                                },
                            },
                        };
                        break;
                    case 2:
                        e.obj.getPath().forEach((element) => {
                            let P = gcj02towgs84(element.lng, element.lat);
                            path.push([P[0], P[1], 0]);
                        });
                        this.sendElementData = {
                            id: this.uuidv4(),
                            name: '线',  //名称
                            resource: {
                                type: 1,
                                user_name: sessionStorage.getItem('user_name'),
                                content: {
                                    type: 'Feature',
                                    properties: {
                                        color: '#19BE6B',
                                        clampToGround: true,
                                    },
                                    geometry: {
                                        type: 'LineString',
                                        coordinates: path,
                                    },
                                },
                            },
                        };
                        break;
                    case 3:
                        e.obj.getPath().forEach((element) => {
                            let P = gcj02towgs84(element.lng, element.lat);
                            path.push([P[0], P[1], 0]);
                        });
                        this.sendElementData = {
                            id: this.uuidv4(),
                            name:'面',
                            resource: {
                                type: 2,
                                user_name: sessionStorage.getItem('user_name'),
                                content: {
                                    type: 'Feature',
                                    properties: {
                                        color: '#E23C39',
                                        clampToGround: true,
                                    },
                                    geometry: {
                                        type: 'Polygon',
                                        coordinates: [path],
                                    },
                                },
                            },
                        };
                        break;
                    default:
                        break;
                }
                this.addDxm=true
              //  this.sendElement();
             /*  let _this=this
                this.$Modal.confirm({
                    title:'是否提交',
                    content:'是否确认提交',
                    okText:'提交',
                    cancelText:'取消',
                    // 点击回调
                    onOk:async ()=>{
                        _this.sendElement();
                    },
                    onCancel:async ()=>{
                     //   console.log('失败')
                        this.map.remove(nowObj);
                       // _this.map.remove(_this.mouseTool)
                        // 清除全部折线
                        //重新生成折线，我把折线写在了方法里，直接调用的。可以直接
                        //把上面的拿过来就可以
                    }
                })*/
               //
               // console.log('this.sendElementData111111111111',this.sendElementData)
            },
            uuidv4() { //随机生成ID
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
                    /[xy]/g,
                    function (c) {
                        const r = (Math.random() * 16) | 0;
                        const v = c === 'x' ? r : (r & 0x3) | 0x8;
                        return v.toString(16);
                    }
                );
            },
            //提交点线面
            sendElement() {
              //修改标注
              if (this.addDxmName == '') {
                this.$Message.warning('请输入标注信息');
                return;
              }
                //添加添加
                this.sendElementData.name= this.addDxmName
                this.sendElementData.resource.content.properties.color= this.colorListNum
                this.$sy_post('/map/api/v1/workspaces/'+this.workspaceID+'/element-groups/'+this.groundId+'/elements',this.sendElementData).then((res) => {
                      if (res.code == 0) {
                        // this.getElementGroups()
                        this.isUpdate=false
                        this.updateid=''
                        this.addDxm=false
                        this.addDxmName=''
                        this.colorListNum='#2D8CF0'
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      this.$notice.error({
                        title: '提交地图元素接口异常',
                        desc: err.toString(),
                      });
                    })
                    .finally(() => {
                      this.spinShow = false;
                    });


            },
          UpdateElement(){
            if (this.addDxmName == '') {
              this.$Message.warning('请输入标注信息');
              return;
            }
            this.updataElementData.name= this.addDxmName
            this.updataElementData.content.properties.color= this.colorListNum
            console.log('111111111111111111',this.updataElementData)
            //修改点线面
            this.$sy_put('/map/api/v1/workspaces/'+this.workspaceID+'/elements/'+this.updateid,this.updataElementData).then(res=>{
              console.log(res)
              this.isUpdate=false
              this.updateid=''
              this.addDxm=false
              this.addDxmName=''
              this.colorListNum='#2D8CF0'
            })


            },
            //刪除点线面
             deleteElement(id){
               this.$sy_delete('/map/api/v1/workspaces/'+this.workspaceID + '/elements/'+id).then(res=>{
                if(res.code===0){
                 // this.map.remove(this.mapList)
                 }
               })
             },
          //修改电线面
          UpdateBut(e){

            console.log('111111',e)
            this.addDxm=true
            this.isUpdate=true
            this.updateid=e.id
            this.addDxmName=e.name
            let type=e.resource.content.geometry.type
            this.colorListNum=e.resource.content.properties.color
            let list=e.resource.content.geometry.coordinates
            let coordinates=[]
            switch(type){
              case 'Point':
                coordinates=[list[0],list[1]]
                break;
              case 'LineString':
                let itemlist=[]
                list.forEach(e => {
                  itemlist=[...itemlist,[e[0], e[1]]]
                });
                coordinates=itemlist
                break;
              default :
                let newlist=[]
                list[0].forEach(e => {
                  newlist=[...newlist,[e[0], e[1]]]
                });
                coordinates=[newlist]
                break;
            }
            this.updataElementData = {
                'name':e.name,
                'content': {
                  'type': 'Feature',
                  'properties':{
                    'color':'#0091FF',
                    'clampToGround': true,
                  },
                  'geometry': {
                    'type': type,
                    'coordinates':coordinates
                  },
                },
            };
          },
            //名称显隐
            changeName(e) {
              this.showName = e;
              let x = document.querySelectorAll('.if_name');
              let y = document.querySelectorAll('.if_name_drone');
              if (x.length > 0) {
                x.forEach((element) => {
                  element.style.visibility = e;
                });
              }
              if (y.length > 0) {
                y.forEach((element) => {
                  element.style.visibility = e;
                });
              }
            },
          // 提交指挥官目标点
          submitSendCommander() {
                  this.$emit(
                      'sendCommander',
                      JSON.parse(JSON.stringify(this.sendCommanderOptions))
                  );
                // 发送给全部在线飞手;
                if (this.config.onLineList.length == 0) {
                    this.$Notice.warning({
                        title: '暂无飞手在线!',
                    });
                    return;
                } else if (this.sendCommanderOptions.local_data[0].lat == 0) {
                    this.$Notice.warning({
                        title: '请先点选目标点',
                    });
                    return;
                } else {
                    this.sendCommanderOptions.loading = true;
                    this.sendCommanderOptions.selectUids = this.config.onLineList.map(
                        (ele) => ele.user_id
                    );
                    this.$emit(
                        'sendCommander',
                        JSON.parse(JSON.stringify(this.sendCommanderOptions))
                    );
                    this.showMore.markerModal = false;
                    this.showMore.marker = false;
                    this.resetSendCommanderOptions();
                    this.toggleMarkerClickOrDragEvent(false);

                    // 设置标记点名称;
                    this.sendMarker.setLabel({
                        offset: new AMap.Pixel(0, -2),
                        content: `<div class="if_name">${this.targetName}</div>`, //设置文本标注内容
                        direction: 'top',
                    });
                    this.changeName(this.showName);
                    this.sendMarker = '';
                }
            },
          resetSendCommanderOptions() {
              this.sendCommanderOptions.loading = false;
              this.sendCommanderOptions.selectUids = [];
              this.sendCommanderOptions.local_data[0].lng = 0;
              this.sendCommanderOptions.local_data[0].lat = 0;
              this.targetIcon = navigation;
          },
          //上云--地图元素
          getElementGroups() {
            this.$sy_get('/map/api/v1/workspaces/' + this.workspaceID + '/element-groups').then((res) => {
              console.log('上云--地图元素',res.data)
                  if (res.code == 0) {
                       this.teamData=[]
                      res.data.forEach(e=>{
                          this.groundId = e.id;
                          if(e.elements!==null&&e.elements.length>0){
                              e.elements.forEach(item=>{
                                this.teamData=[...this.teamData,item]
                                this.initElement(item,item.name)
                              })
                          }

                      })
                     if((res.data[0].elements.length==0&&this.teamData.length==0)||(res.data[1].elements.length==0&&this.teamData.length==0)){
                    //  this.initMap()
                       this.map.remove(this.mapList)
                       this.showMore.deleteList=false
                       this.dynamicStyle=20
                    }
                  }
                })
              /*  .catch((err) => {
                  console.log(err);
                  this.$notice.error({
                    title: '获取地图元素接口异常',
                    desc: err.toString(),
                  });
                })
                .finally(() => {
                  this.spinShow = false;
                });*/
          },
          // 初始化已有地图元素
          initElement(el,name) {
            let item =el.resource.content.geometry.coordinates
            console.log('e11111111111111l',el)
            this.getPointLinePlane(el,item,name)
          },
          getPointLinePlane(el,path,name){ //获取点线面

            let date=[]
            let labelContent="<div class='labelContentClass'>"+name+"</div>"
            let color=el.resource.content.properties.color
            let icon=''
            let img=''
            switch (color) {
              case '#2D8CF0':
                icon=require('@/assets/img/shangyun/map/2D8CF0.png')
                img=require('@/assets/img/shangyun/map/M2D8CF0.png')
                break;
              case '#19BE6B':
                icon=require('@/assets/img/shangyun/map/19BE6B.png')
                img=require('@/assets/img/shangyun/map/M19BE6B.png')
                break;
              case '#212121':
                icon=require('@/assets/img/shangyun/map/212121.png')
                img=require('@/assets/img/shangyun/map/M212121.png')
                break;
              case '#B620E0':
                icon=require('@/assets/img/shangyun/map/B620E0.png')
                img=require('@/assets/img/shangyun/map/MB620E0.png')
                break;
              case '#E23C39':
                icon=require('@/assets/img/shangyun/map/E23C39.png')
                img=require('@/assets/img/shangyun/map/ME23C39.png')
                break;
              case '#FFBB00':
                icon=require('@/assets/img/shangyun/map/FFBB00.png')
                img=require('@/assets/img/shangyun/map/MFFBB00.png')
                break;
              default:
                break;
            }
            this.map.remove(this.nowObj);
            switch (el.resource.type) {
              case 0:
                // 点
                let dateList= wgs84togcj02(path[0],path[1])
                this.MarkerMapShow = new AMap.Marker({
                  map:this.map,
                  position: dateList,
                  icon: img, // 添加 Icon 图标 URL
                  offset: new AMap.Pixel(-10, -10),
                  showPositionPoint: false,
                  zIndex: 100,
                  label: {
                    direction: 'top',
                    content: labelContent,
                  }
                });
                this.mapList.push(this.MarkerMapShow)
               // this.map.add(this.Marker);
                break;
              case 1:
                path.forEach(e=>{
                  let  dateList= wgs84togcj02(e[0],e[1])
                  date=[...date,dateList]
                })
                // 线
                this.MarkerMapShow = new AMap.Polyline({
                  map: this.map,
                  path: date,
                  showDir: true,
                  strokeColor: color, //线颜色
                  // strokeOpacity: 1,     //线透明度
                  strokeWeight: 2, //线宽
                });
                let B = new AMap.Marker({
                  map:this.map,
                  position: date[0],
                  offset: new AMap.Pixel(-5, -5),
                  showPositionPoint: false,
                  zIndex: 100,
                  icon: icon, // 添加 Icon 图标 URL
                  label: {
                    direction: 'top',
                    content: labelContent,
                  }
                });
                this.mapList.push(B)
                this.mapList.push(this.MarkerMapShow)
            /*    this.map.add(B);
                this.map.add(this.showPolyline);*/
                break;
              default :
                // 面
                path[0].forEach(e=>{
                  let  dateList= wgs84togcj02(e[0],e[1])
                  date=[...date,dateList]
                })
                this.MarkerMapShow= new AMap.Polygon({
                  map:this.map,
                  path: date,
                  strokeColor:color, //线颜色
                  fillColor:color,
                  fillOpacity:0.3,
                   strokeWeight: 2, //线宽
                  offset: new AMap.Pixel(-1, -1),
                })

                let A = new AMap.Marker({
                  map:this.map,
                  position: date[0],
                  offset: new AMap.Pixel(-5, -5),
                  showPositionPoint: false,
                  zIndex: 100,
                  icon: icon, // 添加 Icon 图标 URL
                  label: {
                    direction: 'top',
                    content: labelContent,
                  }
                });
                this.mapList.push(A)
                this.mapList.push(this.MarkerMapShow)
         /*       this.map.add(A);
                this.map.add([this.PointLinePlane])*/
                break;
            }
            this.MarkerMapShow.on('click', (e)=>{
           /*   this.MarkerMapShow.setTarget(e.target);
              this.MarkerMapShow.open();*/
              this.UpdateBut(el)
            });
          },

        },
        created() {
            this.localConfig.toolsMovingDistance = (this.config || {}).toolsMovingDistance || 0;
            this.localConfig.tools = (this.config || {}).tools || ['hierarchy', 'zoomIn', 'zoomOut',];
        },
        mounted() {
            this.InitWebsocket(); //临时屏蔽Socket连接
            this.initMap()
          // 获取地图元素列表和图层id
            this.workspaceID = sessionStorage.getItem('sy-workspace_id');
            console.log('.workspaceID++++++++++++++',this.workspaceID)
            this.getElementGroups();
            //连接Websocket
        },
        beforeDestroy() {
            // 关闭页面
            this.websocketclose()
        },
        computed: {
            // 每个地图的id 有可能一个页面多个map
            id: {
              get() {
                return 'commonMap' + mapId++;
              },
            },
            mouseToolPoint() {
                return {
                    opacity: this.elementType === 'pin' ? '1' : '0.6',
                };
            },
            mouseToolLine() {
                return {
                    opacity: this.elementType === 'line' ? '1' : '0.6',
                };
            },
            mouseToolPoly() {
                return {
                    opacity: this.elementType === 'poly' ? '1' : '0.6',
                };
            },
          // 右下角按钮的位置
        /*  dynamicStyle() {
            return (this.config || {}).toolsMovingDistance + 20 || 20;
          },*/
        },
   /*     watch :{
          userAdderssList: {
            deep:true,
            handler:(val, oldVal)=>{
               if(val){

               }
            }
          }
        }*/
    }

</script>


<style lang="scss">
.goBack {
  @include wh(78, 78);
  position: absolute;
  top: 30px;
  left: 30px;
  img {
    width: 100%;
    height: 100%;
  }
}

.marker-cir-wrapper {
  width: 30px;
  height: 30px;
  border: 4px solid rgba(233, 0, 0, 1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  font-size: 16px;
  background-color: #fff;
  color: #000;
}
.m-active {
  border-color: #409eff;
}
.marker-wrapper {
  flex-direction: column;
  justify-content: center;
}

.marker-wrapper .plane-info div {
  white-space: nowrap;
}
.marker-wrapper .user-name {
  background: #333;
  opacity: 0.6;
  border-radius: 2px;
  margin-bottom: 4px;
  color: #fff;
  width: 60px;
  text-align: center;
  // white-space: pre;
  white-space: nowrap; /*超出的空白区域不换行*/
  overflow: hidden; /*超出隐藏*/
  text-overflow: ellipsis; /*文本超出显示省略号*/
}
.marker-wrapper .user-avatar {
  overflow: hidden;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0);
  border: 2px solid rgba(242, 58, 58, 1);
  border-radius: 50%;
  opacity: 1;
}
.marker-wrapper .user-avatar img {
  width: 30px;
  height: 30px;
}
.marker-wrapper .plane-info {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}
.marker-wrapper .plane-power-wrapper {
  width: 72px;
  height: 6px;
  background: rgba(255, 232, 228, 1);
  opacity: 1;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
}
.marker-wrapper .plane-power-wrapper .plane-power {
  width: 20px;
  height: 6px;
  background: rgba(255, 104, 91, 1);
  opacity: 1;
  border-radius: 5px;
}
.marker-wrapper .plane-avatar img {
  width: 32px;
  height: 32px;
}

.ControlModal1 {
  .ivu-modal-body {
    background-color: #e0e0e0;
  }
}
.color-box{
    width: 320px;
    margin: 20px auto;
    label{
      width: 100%;
      height: 60px;
      line-height: 60px;
      span{
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
    }
  ul{
    width: 300px;
    margin: 0 auto;
    overflow: hidden;
    li{
      width: 20px;
      height: 20px;
      float: left;
      margin-right: 20px;
    }
    .active{
      background: #1c1c1c;
    }
  }

}


// 无人机图标样式
.drone-icon {
  position: relative;
  width: 32px;
  height: 32px;
  white-space: pre;
  &:hover > .drone-info {
    display: flex;
  }
  .drone-info {
    min-width: 200px;
    height: 90px;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    color: #fff;
    font-size: 14px;
    padding: 8px;
    position: absolute;
    left: -200px;
    bottom: -90px;
    display: none;
    > div {
      display: flex;
      margin-bottom: 6px;
      align-items: center;
    }
    .drone-info-1 {
      justify-content: space-between;
      img {
        display: inline;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 1px solid #ff685b;
        margin-right: 6px;
        vertical-align: middle;
      }
    }
    .drone-info-2 {
      justify-content: flex-start;
      img {
        display: inline;
        height: 13px;
      }
      span {
        margin-left: 3px;
      }
    }
    .drone-info-3 {
      img {
        display: inline;
        height: 11px;
        margin-right: 5px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.mapWrapper {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: relative;

  .commonMap {
    width: 100%;
    height: 100%;
  }

  .tool-box-wrapper {
    position: absolute;
    bottom: 0;
    padding-bottom: 30px;
    transition: all 0.3s ease-in-out;
    .tool-box {
        margin-top: 20px;
        width: 30px;
        height: 30px;
        background: #000;
        opacity: 0.7;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        &:hover {
          opacity: 1;
        }
        img {
          width: 18px;
        }
        .more-actions {
          opacity: 1;
          position: absolute;
          color: #fff;
          top: 0;
          right: 60px;
          display: flex;
          flex-direction: column;
          border-radius: 5px;
          background-color: #333;
          padding: 10px;
          .act-line {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
            }
            input {
              margin-right: 10px;
              margin-top: 2px;
            }
            span {
              white-space: nowrap;
            }
          }
          hr {
            margin-bottom: 10px;
          }
        }
        .measureBox {
          padding: 0;
          background-color: transparent;
          flex-direction: row;
          > div {
            height: 30px;
            white-space: nowrap;
            background-color: #333;
            opacity: 0.6;
            border-radius: 2px;
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 10px;
            span {
              font-size: 16px;
              color: #fff;
            }
            &:last-child {
              margin-right: 0;
            }
          }
          :nth-child(1) {
            opacity: 1;
          }
        }
      }


  }
  .teamBox {
    width: 200px;
    height: 100%;
    position: absolute;
    overflow: hidden;
    overflow-y:scroll ;
    background: #383838;
    top: 0;
    right: 0;
    z-index: 6666;
    padding-bottom: 30px;
    transition: all 0.3s ease-in-out;

    h3{
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 14px;
      color: #ffffff;
    }
    .teamItem{
      width: 100%;
      margin: 0 auto;
      background:#1F1F1F;
      border: 1px solid #1F1F1F;
      color: white;
      font-size: 14px;
      text-indent: 10px;
      margin-bottom:2px;

      span{
        width: 100px;
        display: inline-block;
        line-height: 50px;
        color: #ffffff;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      i{
        font-size: 20px;
        float: right;
        margin-top: 15px;
        margin-right: 20px;
        cursor: pointer;
        color: #ffffff;
      }
    }
    .teamItem:hover{
      border: 1px solid #29b8f8;
      color: #29b8f8;
    }
  }
  .searchBox {
    @include cl;
    top: 30px;
    width: 500px;
    height: 50px;
    /deep/.ivu-input {
      height: 40px;
      border-radius: 50px;
      border: none;
      padding-left: 55px;
    }

    /deep/.ivu-input:focus {
      outline: none;
      box-shadow: 0 0 0 2px transparent;
      border: 1px solid #409eff;
    }
    /deep/.ivu-input-prefix,
    .ivu-input-suffix {
      @include wh(60, 40);
      line-height: 40px;
      i {
        font-size: 25px;
        line-height: 40px;
      }
    }
  }

  .switch-3d {
    width: 50px;
    height: 50px;
    background: rgba(51, 51, 51, 1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    position: absolute;
    top: 20px;
    cursor: pointer;

    img {
      width: 30px;
      height: 30px;
    }
  }

  .sendCommander {
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    z-index:3;
    width: 240px;
    height: 100%;
    background: #f5f7fa;
    color: #333;
    font-size: 12px;
    > div {
      padding-left: 16px;
      border-bottom: 1px solid #ebebeb;
    }

    .top {
      font-size: 14px;

      height: 40px;
      line-height: 40px;
    }
    .coordinate {
      padding: 18px 16px;
      p {
        font-size: 14px;
        margin-bottom: 10px;
      }
      span {
        display: inline-block;
        width: 170px;
        height: 24px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        line-height: 24px;
        margin-bottom: 8px;
        margin-left: 8px;
        padding-left: 10px;
      }
    }
    .mark_info {
      label {
        vertical-align: top;
      }
      input,
      textarea {
        // display: inline-block;
        width: 170px;
        height: 24px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        line-height: 24px;
        margin-bottom: 8px;
        margin-left: 8px;
        padding-left: 10px;
        outline: 0;
      }
      textarea {
        height: 72px;
        resize: none;
      }
    }
    .mark_style {
      position: relative;
      border-bottom: 0;
      button {
        width: 36px;
        height: 24px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 2px;
        color: #2196f3;
      }
      .icons {
        position: absolute;
        top: 88px;
        li {
          float: left;
          padding: 5px;
          background: #dbdbdb;
        }
      }
      .sendBtn {
        width: 88px;
        height: 28px;
        background: #2196f3;
        color: #fff;
        border-radius: 4px;
        margin-left: 8px;
        margin-top: 25px;
      }
      .sendCancel {
        background-color: #fff;
        color: #2196f3;
        margin-left: 16px;
      }
    }
  }
  .btns {
    justify-content: flex-start;
    font-size: 14px;
    button {
      height: auto;
      line-height: 1;
      font-size: 14px;
      padding: 10px 16px;
    }
    .check-all {
      transform: scale(0.8);
      white-space: nowrap;
    }
  }
  .sendControlMsg {
    padding: 20px 15px;
    background-color: #f6f6f6;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    i {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 30px;
      color: #111;
      cursor: pointer;
    }
    h1 {
      font-size: 16px;
      color: #111;
      margin-top: 10px;
    }
    .dronePilotBox::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .dronePilotBox {
      overflow: auto;
      min-width: 240px;
      max-height: 400px;

      .dronePilot {
        min-width: 240px;
        height: 80px;
        margin: 10px 0;
        padding: 10px;
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        .headImg {
          width: 55px;
          height: 55px;
          border-radius: 55px;
        }
        .Divider {
          width: 2px;
          height: 100%;
          margin: 0 10px;
          background-color: #e0e0e0;
        }
        .msgBox {
          h2 {
            font-size: 18px;
            color: #111;
          }
          h3 {
            font-size: 12px;
            color: #111;
          }
        }
      }
    }
  }
  /deep/.amap-marker-label {
    background: rgba(0, 0, 0, 0.7);
    padding: 0;
    color: #fff;
    border: 0;
    div {
      padding: 2px 4px;
    }
  }
  .show_name {
    position: absolute;
    top: 10px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
  }
}
</style>
