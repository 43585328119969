<template>
  <div class="voice-item flex"
       :class="config.role === 'self' ? 'reverse' : ''"
       @click.stop="togglePlayVoice">
    <div class="icon-voice"
         :class="isClick ? 'icon-voice-play' : ''">
      <i class="voice-circle circle-one"></i>
      <i class="voice-circle circle-two"></i>
      <i class="voice-point"></i>
    </div>
    <div class="voice-time">{{ Math.ceil(config.voiceTimeLength) || 0 }}″</div>
    <audio ref="audio"
           style="display: none"
           :src="config.content"></audio>
  </div>
</template>

<script>
export default {
  name: 'voice-item',
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    // isClick: {
    //   type: Boolean,
    // },
  },
  mounted() {
    // 初始化音频
    this.audio = this.$refs['audio'];
    this.isClick = false;
    if (this.audio) {
      this.audio.addEventListener('canplay', () => {
        console.log('mp3加载完成............', this.config.content);
        this.config.voiceTimeLength = this.audio.duration;
        this.canPlay = true;
      });
      this.audio.addEventListener('ended', () => {
        // this.config.isPlayingVoice = false;
        this.isClick = false;
        // this.$emit('stopOtherAudio', '');
      });
    }
  },
  watch: {
    // config: {
    //   deep: true,
    //   handler: function (val) {
    //     console.log('当被点击的时候触发这个回调 控制是否播放', val);
    //     // 当被点击的时候触发这个回调 控制是否播放
    //     if (this.canPlay) {
    //       if (val.isPlayingVoice) {
    //         this.audio && this.audio.play();
    //       } else {
    //         this.audio && this.audio.pause();
    //       }
    //     }
    //   },
    // },
    // isClick: {
    //   deep: false,
    //   immediate: true,
    //   handler: function (val) {
    //     console.log('回调', val);
    //     if (this.canPlay) {
    //       if (val) {
    //         this.audio && this.audio.play();
    //       } else {
    //         this.audio && this.audio.pause();
    //       }
    //     }
    //   },
    // },
  },
  data() {
    return {
      canPlay: false,
      isClick: false,
    };
  },
  methods: {
    togglePlayVoice() {
      // 抛出播放或者暂停事件
      if (!this.canPlay) {
        this.$Message.error('当前音频暂未加载完成!');
        return;
      }
      //   this.config.isPlayingVoice = !this.config.isPlayingVoice;
      this.isClick = !this.isClick;
      if (this.isClick) {
        this.audio && this.audio.play();
        // this.$emit('stopOtherAudio', this.config.voiceId);
      } else {
        this.audio && this.audio.pause();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.voice-item {
  width: 100%;
  justify-content: flex-start !important;
  .voice-time {
    margin-right: 0.3rem;
  }
}
.icon-voice {
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  $circleColor: black;
  $size: 0.24rem;

  display: inline-block;
  overflow: hidden;
  width: $size;
  height: $size;

  -webkit-transform: rotate(45deg) translate(0);
  transform: rotate(45deg) translate(0);

  .voice-circle {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-color: $circleColor;
    border-style: solid;
    border-width: 3px 3px 0 0;
    border-radius: 0 100% 0 0;
    opacity: 1;
    &.circle-one {
      width: 0.18rem;
      height: 0.18rem;
    }
    &.circle-two {
      width: 0.12rem;
      height: 0.12rem;
    }
  }

  .voice-point {
    position: absolute;
    bottom: 0;
    left: 0;
    border-color: $circleColor;
    width: 0.06rem;
    height: 0.06rem;
    border-radius: 100%;
    overflow: hidden;
    opacity: 1;
    background-color: $circleColor;
  }
}

.icon-voice-play .voice-circle {
  opacity: 0;
  -webkit-animation: vociean 1s infinite;
  animation: vociean 1s infinite;
}
.icon-voice-play .voice-circle.circle-one {
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms;
}

@-webkit-keyframes vociean {
  0% {
    opacity: 1;
  }
  50% {
    opactiy: 0.1;
  }
  100% {
    opactiy: 1;
  }
}
@keyframes vociean {
  0% {
    opacity: 1;
  }
  50% {
    opactiy: 0.1;
  }
  100% {
    opactiy: 1;
  }
}

.reverse {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .icon-voice {
    margin-left: 0;
    margin-right: 0.1rem;
    transform: rotate(-135deg) translate(0);
  }
  .voice-time {
    margin-left: 0.3rem;
    margin-right: 0.1rem;
  }
}
</style>
