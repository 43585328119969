<template>
  <div class="watch">
    <!--人员列表区域-->
    <div class="watchBox" v-show="!rapidShou">
      <!--3D地图切换图标-->
      <!--<div :id="Shou3D ? 'Map3D' : ''"
           class="MapSwitch"
           ref="MapSwitch"
           @click="Map3DSwitch"
           :style="dynamicStyle">
        <img src="../../../assets/img/Icon/Switch3dMap.png" alt="" />
      </div>-->
      <!--高德地图-->
      <Map :config="mapConfig"
           @sendMsg="sendMsgHandler"
           @sendCommander="sendCommanderHandler"
           ref="commonMap"
           v-show="!Shou3D"
           @sendSocketMsg="sendSocketMsg"
           :targetPoint="targetPoint" />
      <!--Cesium地图-->
     <!-- <folder-modal ref="folder"
                    :config="folderConfig"
                    @toggleFolder="toggleFolder">
        <watch-lists ref="watchLists"
                     :dataConfig="watchData"
                     @sendSocketMsg="sendSocketMsg"
                     @mapCenterToUser="mapCenterToUser" />
      </folder-modal>-->
      <Spin size="large"
            fix
            v-if="spinShow"></Spin>
    </div>

    <!--快拼任务区域-->
    <div class="rapid"
         v-if="rapidShou">
      <!--高德地图-->
      <Map :config="rapidMapConfig"
           :rapidInfo="rapidGetTheNodInfo"
           @sendMsg="RapidSendMsg"
           ref="rapidMap"
           @sendSocketMsg="sendSocketMsg" />
      <folder-modal ref="folder"
                    :config="folderConfig"
                    @toggleFolder="toggleFolder2">
<!--        <rapidBiuldFigure ref="rapid"
                          :config="rapidData"
                          :info="rapidGetTheNodInfo"
                          :rapidFeedbackMsg="rapidFeedbackMsg"
                          @sendSocketMsg="sendSocketMsg" />-->
      </folder-modal>
      <div class="videoBox"
           id="videoBox"
           v-if="videoBoxShou"></div>
    </div>
  </div>
</template>

<script>
import Map from '../map/map.vue';
import folderModal from '@/components/statistics/commonComponents/folderModal.vue';
import WatchLists from '@/components/live/watch/watch-lists.vue';
import rapidBiuldFigure from '@/components/live/watch/rapidBiuldFigure.vue';
import Avatar from '@/assets/img/statistics/message.png';
import Api from '@/utils/api.js';
/*import Socket from '@/utils/socket.mixin.js';*/

export default {
  name: 'create-app',  // 实时监控
 /* mixins: [Socket],*/
  components: {
    Map,
    folderModal,
    WatchLists,
    rapidBiuldFigure,
  },
  created() {
    this.user_info = JSON.parse(
      window.sessionStorage.getItem('user_info') || '{}'
    );
  },

  computed: {
    urlParams() {
      return {
        pm_id: this.$route.query.id || this.user_info.pm_id,
        team_id: this.user_info.team_id || '',
      };
    },
    // 右上角3D按钮的位置
    dynamicStyle() {
      var _this = this;
      return {
        right: (_this.mapConfig.toolsMovingDistance || 0) + 30 + 'px',
      };
    },
  },
  mounted() {
    let _this = this;
    // this.fetchData();
    //快速拼图区域++++++++++++++++++++++++++++++++++++++++++
    //进入快速拼图界面方法
    this.rapidMapConfig.rapidData = this.rapidData;
    this.$_bus.$off('showRapidHandler');
    this.$_bus.$on('showRapidHandler', function (info) {
      _this.showRapidHandler(info);
    });

    //更新数据
    this.$_bus.$off('updateRapid');
    this.$_bus.$on('updateRapid', function (data) {
      _this.updateRapid(data);
    });
  },
  data() {
    return {
      spinShow: false,
      mapConfig: {
        toolsMovingDistance: 345,
        tools: [
         // 'command',
          /*'marker',*/
          //'fix',
          'search',
         // 'measure',
        //  'hierarchy',
          'zoomIn',
          'zoomOut',
          'element',
        ],
        onLineList: [],
        updateBySocket: false,
        isLive: true, // 显示名称控件
      },
      folderConfig: {
        width: 341,
        backgroundColor: '#FCFCFC',
        direction: 'right',
      },
      watchData: {
        type: 'normal',
        title: '成员',
        data: [],
      },
      keyTemp: {
        UserLongitude: '',
        UserLatitude: '',
        IsPublish: 0,
        PUBLISH_NAME: '',
        DroneLongitude: '',
        DroneLatitude: '',
        head_icon_path: '',
        speed: '',
        DroneAltitude: '',
        DronePitch: '',
        DroneRoll: '',
        remote: '',
        figure_pass: '',
        remote_power: '',
        dianliang: '',
        temperature: '',
        voltage: '',
        hadNewMsg: 0,
        NewMsg: 0,
        msg_content: '',
        from_id: '',
        msg_type: '',
        from_name: '',
        time_line: '',
        target_id: '',
        target_type: '',
        power: '',
        isPlayingLive: false,
        airPlaneAcceptedSpeakControlStatus: 'none', //无人机是否已经接收喊话控制 none waitingAccept accepted
        airPlaneAcceptedControlStatus: 'none', //无人机是否已经接收远程控制 none waitingAccept accepted
      },
      Shou3D: false,
      // 创建快速拼图任务
      rapidShou: false,
      rapidGetTheNodInfo: null, //快拼被选中的用户数据
      rapidMapConfig: {
        toolsMovingDistance: 340,
        tools: ['search', 'measure', 'hierarchy', 'zoomIn', 'zoomOut'],
        rapidStatus: false,
        rapidData: null,
      },
      rapidData: {
        task3DModel: '',
        taskName: '',
        id: '',
        activeMarker: -1,
        airLinePoints: 0,
        ariLineLength: 0, //航线长度
        flyingExpectTime: 0, //预计飞行时间
        speed: 8, //飞行速度 (m/s)
        height: 100, //飞行高度 (m)
        targetHeight: 0, //目标高度 (m)
        relativeHeight: 100, //相对高度 (m)
        select: 1, //任务完成动作
        pointHeight: 50,
        pointAngle: 0, //云台俯仰角度 (°)
        airArea: 0, //区域面积
        photoGraphTimes: 0, //照片数量
        courseOverlapRate: 80, //航线重叠率 (%)
        sideOverlapRate: 70, //旁向重叠率 (%)
        courseAngle: 0, //航线角度
        points: [],
        waypoint: [],
        defaultFov: 84,
        rapidPathArray: [],
      },
      rapidFeedbackMsg: null, //快拼ios端反馈信息
      videoBoxShou: false,
      player: null,
      targetPoint: {},
    };
  },
  methods: {
    // 地图定位到用户的位置
    mapCenterToUser(data) {
      if (!data.UserLongitude || !data.UserLatitude) {
        this.$notice.error({
          title: '当前用户未推送位置信息!',
        });
        return;
      }
      let position = [
        parseFloat(data.UserLongitude),
        parseFloat(data.UserLatitude),
      ];
      this.$refs['commonMap'].backCenter(position);
    },
    // 发送socket消息
    sendSocketMsg(data, notice = true) {
      // console.log("socker", data);
    /*  this.sendMessage(data);
      if (data.dataType) {
        return;
      }
      this.$notice.warning({
        title: '消息发送成功!',
      });*/
    },
    // 更新位置信息
    updateEleLocationInfo(ele, data) {
      // console.log("watchData遍历的各项", ele);
      // console.log("ws传入的在线数据", data);
      Object.keys(data).forEach((key) => {
        ele[key] = data[key];
      });
      ele.isonline = 1;
      if (!ele.positionList) {
        ele.positionList = [];
      } else {
        if (
          data.DroneLongitude &&
          data.DroneLatitude &&
          data.DroneLongitude !== 'NaN' &&
          data.DroneLatitude !== 'NaN' &&
          data.DroneLongitude !== '0' &&
          data.DroneLatitude !== '0'
        ) {
          ele.positionList.push([
            parseFloat(data.DroneLongitude),
            parseFloat(data.DroneLatitude),
          ]);
        }
      }
      // (ele.positionList || (ele.positionList = [])).push([parseFloat(data.DroneLongitude) || 0, parseFloat(data.DroneLatitude) || 0])
      this.updateOnLineCount(this.watchData.data);
    },
    // 更新新消息
    updateMsgInfo(ele, data) {
      Object.keys(data).forEach((key) => {
        ele[key] = data[key];
      });
      ele.isonline = 1;
      if (!this.$refs['watchLists'].showChat) {
        ele.hadNewMsg = 1;
        ele.NewMsg++;
      }
      if (ele.from_id.toString() !== this.user_info.user_id.toString()) {
        ele.msgList.push({
          avatar:
            ele.from_id.toString() === this.user_info.user_id.toString()
              ? this.user_info.head_icon
              : ele.head_icon_path,
          role:
            ele.from_id.toString() === this.user_info.user_id.toString()
              ? 'self'
              : 'remote',
          msg_type: data.msg_type || '',
          content: data.msg_content,
          isPlayingVoice: false,
          voiceTimeLength: 0,
          voiceId: ele.msgList.length - 1,
        });
      }
      this.updateOnLineCount(this.watchData.data);
    },
    // 更新在线状态
    updateOnLineInfo(ele, data) {
      ele.isonline = 0;
      ele.positionList = [];
      this.updateOnLineCount(this.watchData.data);
    },
    // 更新是否接受了喊话控制
    updateSpeakContorlStatus(ele, data) {
      ele.isonline = 1;
      if (data.msg_type === 1) {
        this.$notice.warning({
          title: '无人机拒绝接受喊话控制!',
        });
        ele.airPlaneAcceptedSpeakControlStatus = 'none';
      }
      if (
        ele.airPlaneAcceptedSpeakControlStatus === 'waitingAccept' &&
        data.msg_type === 2
      ) {
        this.$notice.success({
          title: '无人机同意接受喊话控制!',
        });
        ele.airPlaneAcceptedSpeakControlStatus = 'accepted';
      }
      // if (data.msg_type === 3) {
      //   this.$notice.warning({
      //     title: '无人机关闭喊话控制!'
      //   })
      //   ele.airPlaneAcceptedSpeakControlStatus = 'none';
      // }
      if (data.msg_type === 5) {
        this.$notice.success({
          title: '无人机喊话器播放成功!',
        });
      }
      this.updateOnLineCount(this.watchData.data);
    },
    // 更新是否接受了控制
    updateContorlStatus(ele, data) {
      // console.log("更新是否接受了控制", data);
      // console.log(ele);
      ele.isonline = 1;
      if (data.dataType === 1) {
        this.$notice.warning({
          title: '无人机拒绝接受远程控制!',
        });
        ele.airPlaneAcceptedControlStatus = 'none';
      }
      if (
        ele.airPlaneAcceptedControlStatus === 'waitingAccept' &&
        data.dataType === 17
      ) {
        this.$notice.success({
          title: '无人机同意接受远程控制!',
        });
        ele.airPlaneAcceptedControlStatus = 'accepted';
      }
      // if (data.msg_type === 3) {
      //   this.$notice.warning({
      //     title: '无人机关闭控制!'
      //   })
      //   ele.airPlaneAcceptedControlStatus = 'none';
      // }
      // console.log(
      //   "更新是否接受了控制_2,this.watchData.data",
      //   this.watchData.data
      // );
      this.updateOnLineCount(this.watchData.data);
    },
    // 统计更新在线个数
    updateOnLineCount(data, updateBySocket = true) {
      // console.log("统计更新在线个数", data);
      // console.log("统计更新在线个数_2", this.watchData);

      this.mapConfig.onLineList = [];
      data.forEach((ele) => {
        ele.onLineCount = 0;
        ele.teamuser.forEach((e) => {
          if (!!e.isonline) {
            ++ele.onLineCount;
            if (
              !this.mapConfig.onLineList.some(
                (item) => item.user_id === e.user_id
              )
            ) {
              this.mapConfig.onLineList.push(e);
            }
          }
        });
      });
      this.mapConfig.updateBySocket = updateBySocket;
      this.reRankDataByOnLine();
    },
    // 拓展子组件要用的属性到目前对象上面
    expandWatchData(data) {
      data.forEach((ele) => {
        ele.onLineCount = 0;
        ele.teamuser.forEach((e) => {
          Object.keys(this.keyTemp).forEach((key) => {
            e[key] = this.keyTemp[key];
          });
          e.comment = ele.comment;
          e.positionList = [];
          e.msgList = [];
          e.selected = false;
        });
      });
    },
    // 更新位置
    updateLocation(data) {
      try {
        this.watchData.data.forEach((ele) => {
          ele.teamuser.forEach((e) => {
            if (
              e.user_id &&
              data.uid &&
              e.user_id.toString() === data.uid.toString()
            ) {
              if (
                data.UserLatitude &&
                data.UserLongitude &&
                data.UserLatitude !== 'NaN' &&
                data.UserLongitude !== 'NaN'
              ) {
                this.updateEleLocationInfo(e, data);
              }
            }
          });
        });
      } catch (e) {
        console.log(e);
        this.$Notice.success({
          title: '更新位置失败!',
          desc: e.toString(),
        });
      }
    },
    // 更新消息
    updateMsg(data) {
      try {
        this.watchData.data.forEach((ele) => {
          ele.teamuser.forEach((e) => {
            if (
              e.user_id &&
              data.data[0].from_id &&
              e.user_id.toString() === data.data[0].from_id.toString()
            ) {
              this.updateMsgInfo(e, data.data[0]);
              if (data.data[0].msg_type == 'user_local') {
                // 更新目标点
                this.targetPoint = data.data[0];
              }
            }
          });
        });
      } catch (e) {
        console.log(e);
        this.$Notice.success({
          title: '更新消息失败!',
          desc: e.toString(),
        });
      }
    },
    // 更新喊话控制
    updateSpeakControl(data) {
      try {
        this.watchData.data.forEach((ele) => {
          ele.teamuser.forEach((e) => {
            if (
              e.user_id &&
              data.data.from_id &&
              e.user_id.toString() === data.data.from_id.toString()
            ) {
              this.updateSpeakContorlStatus(e, data.data);
            }
          });
        });
      } catch (e) {
        console.log(e);
        this.$Notice.success({
          title: '更新喊话控制信息失败!',
          desc: e.toString(),
        });
      }
    },
    // 更新远程控制
    updateControl(data) {
      // console.log(" 更新远程控制", data);
      try {
        this.watchData.data.forEach((ele) => {
          ele.teamuser.forEach((e) => {
            if (
              e.user_id &&
              data.from_uid &&
              e.user_id.toString() === data.from_uid.toString()
            ) {
              // console.log(" 更新远程控制状态", data);
              // console.log(e);
              this.updateContorlStatus(e, data);
            }
          });
        });

        // 群体飞控数据状态更新
        this.mapConfig.onLineList.forEach((e, ind) => {
          if (e.user_id && data.from_uid && e.user_id == data.from_uid) {
            // console.log("群体飞控数据状态更新", data.dataType);
            if (data.dataType === 1) {
              this.mapConfig.onLineList[ind].airPlaneAcceptedControlStatus =
                'none';
              // console.log("群体飞控接受了远程控制");
            }
            if (
              this.mapConfig.onLineList[ind].airPlaneAcceptedControlStatus ===
                'waitingAccept' &&
              data.dataType === 17
            ) {
              this.mapConfig.onLineList[ind].airPlaneAcceptedControlStatus =
                'accepted';
              // console.log("群体飞控接受了远程控制");
            }
          }
        });
      } catch (e) {
        console.log(e);
        this.$Notice.success({
          title: '更新远程指挥信息失败!',
          desc: e.toString(),
        });
      }
    },
    // 更新在线
    updateOnLine(data) {
      try {
        this.watchData.data.forEach((ele) => {
          ele.teamuser.forEach((e) => {
            if (
              e.user_id &&
              data.uid &&
              e.user_id.toString() === data.uid.toString()
            ) {
              this.updateOnLineInfo(e, data);
            }
          });
        });
      } catch (e) {
        console.log(e);
        this.$Notice.success({
          title: '更新在线状态失败!',
          desc: e.toString(),
        });
      }
    },

    // 消息类别处理
    socketMsgHandler(data) {
      // console.log("消息类别处理", data);
      let type = data.type || data.msg;
      switch (type) {
        case 'location':
          // 更新位置
          this.updateLocation(data);
          //快拼界面传递数据
          if (this.rapidShou) {
            this.RapidSocketMSG(data);
          }
          break;
        case 'user_msg':
          this.updateMsg(data);
          break;
        case 'send_Speaker':
          this.updateSpeakControl(data);
          break;
        case 'control':
          this.updateControl(data);
          break;
        case 'kick_off':
          break;
        case 'location_logout':
          this.updateOnLine(data);
          break;
        default:
          return;
      }
    },
    // 发送自己的定位消息
    connectSocket() {
      let param = {
        type: 'location', //消息标识
        sb: '1',
        uid: this.user_info.user_id,
        name: this.user_info.name,
        account: this.user_info.account,
        user_type: this.user_info.type,
        head_icon_path: this.user_info.head_icon,
        DroneLatitude: '',
        DroneLongitude: '',
        DroneYaw: '',
        DroneAltitude: '',
        HomeLatitude: '',
        HomeLongitude: '',
        PUBLISH_NAME: '',
        UserLatitude: '',
        UserLongitude: '',
        PointLongitude: '',
        PointLatitude: '',
        DRONETYPE: '',
        DRONEMODEL: '',
        pm_first_id: '',
        Client: '1',
        address: '',
        pm_second_id: this.pm_id,
        track_tag: 0,
        track_id: 0,
        highly: '',
        distance: '',
        PM: '',
        vertical_speed: '',
        horizontal_velocity: '',
        power: '',
        PM10: '',
        Wendu: '',
        Shidu: '',
        Jiaquan: '',
        TVOC: '',
        IsPublish: 0,
        sendIndex: 0,
        run_time: '',
        satellite_signal: 0,
        team_id: '',
        run_time: '',
      };
      this.connectWebsocket(
        Api.getWebSocketUrl(),
        param,
        this.socketMsgHandler
      );
    },
    // generateListMap(arr = []) {
    //   arr.forEach((ele, index) => {
    //     (ele.teamuser || (ele.teamuser = [])).forEach((ele1, index1) => {
    //       this.listMap[ele1.user_id] = {
    //         proIndex: index,
    //         teamIndex: index1
    //       }
    //     })
    //   })
    // },
    // 指点飞行处理
    sendMsgHandler(sendObj) {
      let params = {
        target_id: sendObj.selectUids,
        type: sendObj.type,
        local_data: [
          {
            lat: sendObj.latitude,
            lng: sendObj.longitude,
            height: sendObj.height,
          },
        ],

        // altitude: sendObj.height,
        // longitude: sendObj.longitude,
        // latitude: sendObj.latitude,
        tag: sendObj.markerType, // 目标点类型
        targetName: sendObj.targetName, // 标注点名称
        targetRemarks: sendObj.targetRemarks, // 标注点备注
      };
      this.$post(Api.sendLocation(), params)
        .then((res) => {
          // console.log(res);
          if (res.code === 1) {
            this.$Notice.success({
              title: '发送成功!',
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notice.error({
            title: '发送失败!',
            desc: err.toString(),
          });
        });
    },
    // 指挥官目标点处理
    sendCommanderHandler(sendObj) {
      let params = {
        target_id: sendObj.selectUids,
        type: sendObj.type,
        local_data: sendObj.local_data,
        tag: sendObj.markerType, // 目标点类型
      };
      // console.log(params);
      this.$post(`/android/Commander/sendCommander`, params)
        .then((res) => {
          if (res.code === 1) {
            this.$Notice.success({
              title: '发送成功!',
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notice.error({
            title: '发送失败!',
            desc: err.toString(),
          });
        });
    },
    // 在线的顶上来
    reRankDataByOnLine() {
      this.watchData.data.forEach((ele) => {
        ele.teamuser = [
          ...ele.teamuser.filter((e) => e.isonline),
          ...ele.teamuser.filter((e) => !e.isonline),
        ];
      });
    },
    // 拉取初始化数据
    // fetchData() {
    //   this.$post(Api.getLiveAdminList(), this.urlParams)
    //     .then((res) => {
    //       if (res.data) {
    //         this.expandWatchData(res.data);
    //         this.watchData.data = res.data;
    //         this.updateOnLineCount(this.watchData.data, false);
    //         // this.generateListMap(this.watchData.data)
    //         this.reRankDataByOnLine();
    //         this.connectSocket(); //临时屏蔽Socket连接
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.$notice.error({
    //         title: "一级管理员监控列表接口异常,返回无数据",
    //         desc: err.toString(),
    //       });
    //     })
    //     .finally(() => {
    //       this.spinShow = false;
    //     });
    // },
    // 切换展示悬浮框的时候 需要通知map修改一些按钮的位置
    toggleFolder() {
      this.mapConfig.toolsMovingDistance = this.mapConfig.toolsMovingDistance
        ? 0
        : 345; // 右侧悬浮框移动
    },
    Map3DSwitch() {
      this.Shou3D = !this.Shou3D;
    },
    getRem(val) {
      return val + 'px';
    },

    // 快拼任务区域
    // 侧边栏
    toggleFolder2() {
      this.rapidMapConfig.toolsMovingDistance = this.rapidMapConfig
        .toolsMovingDistance
        ? 0
        : 340;
    },
    //显示快拼
    showRapidHandler(data) {
      this.rapidShou = true;
      this.rapidMapConfig.rapidStatus = true;
      this.rapidGetTheNodInfo = data;
    },

    RapidSocketMSG(data) {
      if (this.rapidGetTheNodInfo && this.rapidGetTheNodInfo.uid == data.uid) {
        this.rapidGetTheNodInfo = data;
      }
    },
    //更新数据
    updateRapid(data) {
      this.rapidData = data;
    },
    // 地图发出来的信息
    RapidSendMsg() {},
  },
};
</script>

<style lang="scss" scoped>
.watch {
  width: 100%;
  height: 100%;
  //人员列表
  .watchBox {
    width: 100%;
    height: 100%;
    position: relative;
    .MapSwitch {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background-color: #000;
      opacity: 0.7;
      cursor: pointer;
      position: absolute;
      top: 50px;
      transition: all 0.3s ease-in-out;
      z-index: 100;
      img {
        width: 80%;
        height: auto;
      }
    }
    #Map3D {
      opacity: 1;
    }
  }
  //快拼
  .rapid {
    width: 100%;
    height: 100%;
    position: relative;
    .videoBox {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 400px;
      height: 250px;
      background-color: #b7b7b7;
    }
  }
}
</style>
