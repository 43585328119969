<template>
  <div class="watch-list">
    <!-- <div class="title">
      {{ dataConfig.title }}
    </div> -->
    <img class="icon"
         v-if="$public"
         :src="pilot"
         @click="PilotPlayModal = !PilotPlayModal" />
    <div class="lists">
      <template>
        <div v-if="dataConfig.data && dataConfig.data.length">
          <div :class="$CSXF?'TeamChatbottom':'TeamChatbottom borderRadius'"
               @click="TeamChat">
            团队聊天
          </div>
          <ChatItem v-for="(chat, index) in dataConfig.data"
                    :key="index"
                    :info="Object.assign({}, chat, chat)"
                    @mapCenterToUser="mapCenterToUser(chat)"
                    @click.native="clickHandle(chat, index)" />
        </div>

        <!--<Collapse v-if="dataConfig.data && dataConfig.data.length">-->
        <!--<Panel-->
        <!--:name="index + ''"-->
        <!--v-for="(item, index) in dataConfig.data"-->
        <!--:key="index"-->
        <!--&gt;-->
        <!--<span class="header">-->
        <!--<span class="status" :style="getStyleObj(item)"></span>-->
        <!--<span class="h-title">{{ item.team_name }}</span>-->
        <!--<span class="count">{{ item.onLineCount }}/{{ item.ucount }}</span>-->
        <!--</span>-->
        <!--<div slot="content">-->
        <!--<chat-item-->
        <!--v-for="(chat, index) in item.teamuser"-->
        <!--:key="index"-->
        <!--:type="dataConfig.type"-->
        <!--:info="chat"-->
        <!--@mapCenterToUser="mapCenterToUser(chat)"-->
        <!--@click.native="clickHandle(chat)"-->
        <!--/>-->
        <!--</div>-->
        <!--</Panel>-->
        <!--</Collapse>-->
        <div class="no-data"
             v-else>
          <img :src="noData"
               alt="" />
          <p>暂无用户</p>
        </div>
      </template>
    </div>
    <ChatModal @cancel="cancelHandle"
               :showModal="showChat"
               :info="liveInfo"
               :teamData="dataConfig"
               :operations="operations"
               @sendSocketMsg="sendSocketMsg" />

    <!--Pilot直播流播放-->
    <!--弹窗区-->
    <Modal footer-hide
           width="1100"
           v-model="PilotPlayModal"
           class-name="PilotPlay-modal"
           @on-visible-change="init">
      <div slot="header"
           class="header">
        <span>Pilot直播流播放</span>
        <i class="ivu-icon ivu-icon-md-repeat"
           @click="init"></i>
      </div>
      <div class="content">
        <div class="list">
          <Spin fix
                v-if="spinShow"
                size="large"></Spin>
          <div class="item"
               :class="{ select: change == ind }"
               v-for="(item, ind) in PilotPlayList"
               :key="ind"
               @click="PilotPlay(item, ind)">
            <div class="head_portrait">
              <img :src="item.head_icon"
                   alt="" />
            </div>
            <span>直播{{ ind + 1 }}</span>
            <!-- <span>{{ item.name }}</span> -->
            <i class="ivu-icon ivu-icon-ios-arrow-dropright"></i>
          </div>
          <p v-if="PilotPlayList.length === 0">暂无数据</p>
        </div>
        <div class="videoBox">
          <div id="video"
               style="background-color: #333333; width: 100%; height: 100%"></div>
          <!-- <video id="video"
                 autoplay
                 style="width: 100%; height:100%; object-fit: contain;"
                 @click.capture.stop=""></video> -->
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import ChatModal from './chat-modal.vue';
import ChatItem from './chat-item.vue';
import pilot from '@/assets/img/Icon/Pilot.png';
import noData from '@/assets/img/noData.png';
// import aaa from '@/assets/img/aaa.png';
import not_live from '@/assets/img/statistics/not_live.png';
import { GetGroupMsgRecord } from '@/utils/javaApi.js';
import { getPrivateChatRecord } from '@/utils/javaApi.js';
export default {
  name: 'watch-list',
  components: {
    ChatItem,
    ChatModal,
  },
  props: {
    dataConfig: {
      type: Object,
      default: () => {},
    },
    TeamChatData: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.user_info = JSON.parse(sessionStorage.getItem('user_info') || '{}');
    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      this.$emit('fetchData', value);
      this.$emit('changeTeamData', value);
      if (this.user_info.team_id == value[value.length - 1]) {
        this.TeamChatShou = true;
      } else {
        this.TeamChatShou = false;
      }
    });
  },
  data() {
    return {
      pilot,
      //   aaa,
      noData,
      not_live,
      showChat: false,
      liveInfo: {},
      operations: [
        'dash-board',
        'live',
        'chat',
        'send-voice',
        'remote-control',
        'speak-control',
      ],
      PilotPlayModal: false,
      PilotPlayList: [],
      player: null,
      change: null,
      spinShow: false,
      peopleList: [],
      chatId: 0,
      TeamChatShou: false,
    };
  },
  watch: {
    'dataConfig.data': {
      deep: true,
      handler: function (val) {
        val.forEach((element) => {
          //   if (element.user_id == 923) {
          //     console.log(element);
          //   }
          if (element.user_id == this.chatId) {
            this.liveInfo = Object.assign({}, element, element);
          }
        });
      },
    },
    showChat(val) {
      if (val) {
        this.dataConfig.data.map((el) => {
          if (el.user_id == this.chatId && val == true) {
            // el.hadNewMsg = 0;
            el.NewMsg = 0;
          }
        });
      }
    },
    // liveInfo: {
    //   deep: true,
    //   handler: function (val) {
    //     console.log("watch-list的监听liveInfo", this.dataConfig); // 确定正常监听,只在发生动作时变化
    //   },
    // },
  },
  //   mounted() {
  //     var _this = this;
  //     this.$_bus.$off('showChatHandler');
  //     this.$_bus.$on('showChatHandler', function (data) {
  //       _this.showChatHandler(data);
  //     });
  //   },
  methods: {
    // 抛出要发送的消息
    sendSocketMsg(data) {
      this.$emit('sendSocketMsg', data);
    },
    // 抛出定位事件的数据
    mapCenterToUser(item) {
      if (!item.is_online) return;
      this.$emit('mapCenterToUser', item);
    },
    // 处理展示聊天框事件
    showChatHandler(item) {
      console.log('展示聊天框事件--群聊记录', item);
      console.log('展示聊天框事件--群聊记录', window.location.host);
      // console.log("dataConfig", this.dataConfig);
      if (this.dataConfig.type === 'normal') {
        if (item.isTeam) {
          this.operations = ['chat', 'send-voice'];
        } else {
          this.operations = [
            'dash-board',
            'live',
            'chat',
            'send-voice',
            'remote-control',
            'speak-control',
          ];
        }
        // item.hadNewMsg = 0;
        item.NewMsg = 0;
        this.liveInfo = item;
        this.showChat = true;
        this.chatId = item.user_id;
      }
    },
    // 超管点击进入一级管理员看到的团队列表
    clickHandle(item) {
      item.msgList = [];
      let list = item.msgList;
      //获取聊天历史记录
      let userId = this.user_info.user_id;
      this.$post(getPrivateChatRecord(), {
        user_id: userId,
        to_user_id: item.user_id,
      })
        .then((res) => {
          if (res.data) {
            //历史记录数据过滤
            for (let i = 0, len = res.data.length; i < len; i++) {
              if (res.data[i] !== '') {
                let isSelf = res.data[i].user_id == userId;
                if (res.data[i].type == 'user_msg') {
                  list.push({
                    avatar: isSelf
                      ? this.user_info.head_icon
                      : res.data[i].head_icon,
                    role: isSelf ? 'self' : 'remote',
                    msg_type: 'normal',
                    content: res.data[i].content,
                    isPlayingVoice: false,
                    voiceTimeLength: res.data[i].time_leng,
                    //   name: isSelf ? this.user_info.name : res.data[i].name,
                  });
                } else if (res.data[i].type == 'voice') {
                  list.push({
                    avatar: isSelf
                      ? this.user_info.head_icon
                      : res.data[i].head_icon,
                    role: isSelf ? 'self' : 'remote',
                    msg_type: 'voice',
                    content: res.data[i].content,
                    isPlayingVoice: false,
                    voiceTimeLength: res.data[i].time_leng,
                    voiceId: i - 1,
                    //   name: isSelf ? this.user_info.name : res.data[i].name,
                  });
                } else if (res.data[i].type == 'voice_phone') {
                  list.push({
                    avatar: isSelf
                      ? this.user_info.head_icon
                      : res.data[i].head_icon,
                    role: isSelf ? 'self' : 'remote',
                    msg_type: 'voice_phone',
                    content: res.data[i].content,
                    isPlayingVoice: false,
                    voiceTimeLength: res.data[i].time_leng,
                    //   name: isSelf ? this.user_info.name : res.data[i].name,
                  });
                }
                /////////
              }
            }
            console.log('聊天记录', item.msgList);
            this.showChatHandler(item);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      //   if (this.dataConfig.type === 'super') {
      //     this.$router.push({
      //       path: '/live/watch-lists',
      //       query: {
      //         id: item.pm_id,
      //       },
      //     });
      //   }
    },
    // 隐藏弹窗
    cancelHandle() {
      this.showChat = false;
    },
    getStyleObj(item) {
      // let color = ''
      // switch(item.testType) {
      //   case 'red':
      //     color = '#FF685B';
      //     break;
      //   case 'orange':
      //     color = '#FF9500';
      //     break;
      //   case 'green':
      //     color = '#61D16B';
      //     break;
      //   case 'blue':
      //     color = '#4C98FF';
      //     break;
      //   case 'purple':
      //     color = '#C179E5';
      //     break;
      //   default:
      //     color = 'red';
      // }
      return {
        backgroundColor: item.comment || 'transparent',
      };
    },
    // 加载列表
    init() {
      this.spinShow = true;
      if (this.PilotPlayModal) {
        this.$post('/live/getPointPush', {
          client: 'web',
        }).then((res) => {
          this.spinShow = false;
          if (res.code == 1) {
            if (res.data) {
              this.PilotPlayList = res.data;
              console.log('', this.PilotPlayList);
            } else {
              if (this.player !== null) {
                this.player.videoClear();
                this.player = null;
              }
            }
          } else {
            this.$Message.error(res.msg_customer);
          }
        });
      } else {
        this.player.videoClear();
        this.player = null;
        this.change = null;
      }
    },
    // 播放直播
    PilotPlay(item, ind) {
      this.change = ind;
      if (this.player !== null) {
        this.player.destroy();
      }
      console.log('=======',item);
      this.player = new TcPlayer('video', {
        // "m3u8": item.play_url.m3u8_url,
        flv: item,
        h5_flv: true,
        // rtmp: item.play_url.default_url,
        live: true,
        // "controls":'none',
        autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
        width: '100%', //视频的显示宽度
        height: '100%', //视频的显示高度
      });

      // ========
      ///////
      /**
       * flv.js
       * 离线的flv播放器
       * 不依赖flash
       * 目前作为通用flv播放器,取代tcplayer
       */
      // if (this.player !== null) {
      //   this.player.pause();
      //   this.player.unload();
      //   this.player.detachMediaElement();
      //   this.player.destroy();
      //   this.player = null;
      // }
      // this.$nextTick(() => {
      //   if (flvjs.isSupported()) {
      //     this.player = flvjs.createPlayer({
      //       type: 'flv',
      //       url: res.data.flv_url,
      //       isLive: true,
      //     });
      //     this.player.attachMediaElement(document.getElementById('video'));
      //     this.player.load();
      //     this.player.play();
      //   } else {
      //     this.$message.error('不支持flv格式');
      //     return;
      //   }
      // });
      // ========

      //   if (this.player !== null) {
      //     this.player.videoClear();
      //     this.player = null;
      //   }
      //   let videoObject = {
      //     container: "#video", //容器的ID或className
      //     variable: "player", //播放函数名称
      //     autoplay: true,
      //     live: true,
      //     video: res.data.default_url,
      //     loaded: "loadedHandler", //当播放器加载后执行的函数
      //   };
      //   this.player = new ckplayer(videoObject);
    },
    //团队监控列表
    // changeTeamData(value) {
    //   console.log('团队切换时-value', value);
    //   this.$emit('fetchData', value[value.length - 1]);

    //   this.$emit('changeTeamData', value[value.length - 1]);
    //   if (this.user_info.team_id == value[value.length - 1]) {
    //     this.TeamChatShou = true;
    //   } else {
    //     this.TeamChatShou = false;
    //   }
    // },
    //打开团队聊天
    TeamChat() {
      console.log('团队聊天id', this.TeamChatData);
      //获取团队聊天历史记录
      let teamId = -1;
      // 超管可与任意团队群聊
      if (this.$CSTJ) {
        if (
          this.user_info.team_level === 0 &&
          this.user_info.is_manager === 1 &&
          sessionStorage.getItem('team_id') !== null &&
          sessionStorage.getItem('team_id') !== '-1'
        ) {
          teamId = Number(sessionStorage.getItem('team_id'));

          this.liveInfo.name = '团队聊天';
        } else {
          teamId = this.user_info.team_id; // 如果不存在，或者==-1
        }
      }
      let userId = this.user_info.user_id;
      this.$post(GetGroupMsgRecord(), {
        team_id: teamId,
      })
        .then((res) => {
          if (res.data) {
            this.TeamChatData.msgList = [];
            //历史记录数据过滤
            let msgList = this.TeamChatData.msgList;
            for (let i = 0, len = res.data.length; i < len; i++) {
              if (res.data[i] !== '') {
                let isSelf = res.data[i].user_id == userId;
                if (res.data[i].msg_type == 'normal') {
                  msgList.push({
                    avatar: isSelf
                      ? this.user_info.head_icon
                      : res.data[i].head_icon,
                    role: isSelf ? 'self' : 'remote',
                    msg_type: 'normal',
                    content: res.data[i].msg_content,
                    isPlayingVoice: false,
                    voiceTimeLength: res.data[i].msg_attribute,
                    name: isSelf ? this.user_info.name : res.data[i].name,
                  });
                } else if (res.data[i].msg_type == 'voice') {
                  msgList.push({
                    avatar: isSelf
                      ? this.user_info.head_icon
                      : res.data[i].head_icon,
                    role: isSelf ? 'self' : 'remote',
                    msg_type: 'voice',
                    content: res.data[i].msg_content,
                    isPlayingVoice: false,
                    voiceTimeLength: res.data[i].msg_attribute,
                    voiceId: i - 1,
                    name: isSelf ? this.user_info.name : res.data[i].name,
                  });
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.showChatHandler(this.TeamChatData);
    },
  },
  beforeDestroy() {
    if (this.player !== null) {
      this.player.destroy();
      //   this.player.videoClear();
      this.player = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.watch-list {
  width: 100%;
  height: calc(100% - 75px);
  // background-color: #fcfcfc;
  // color: #707070;
  font-size: 14px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 18px;
    color: #fff;
    flex: 0 0 38px;
    text-align: center;
    margin-bottom: 10px;
    border-bottom: 1px solid $xf_hui3;
    // .icon {
    //   width: 50px;
    //   height: auto;
    //   margin-right: 20px;
    //   cursor: pointer;
    // }
  }
  .icon {
    width: 0.5rem;
    height: auto;
    margin: 0.1rem 0.2rem 0.1rem 2rem;
    cursor: pointer;
  }
  .lists {
    flex: 1;
    overflow-y: auto;

    /deep/ {
      .ivu-collapse {
        // background-color: #fcfcfc;
        border-left: none;
        border-top: 0;
        // .ivu-collapse-item {
        //   &:last-child {
        //     // border-bottom: 1px solid #dcdee2;
        //   }
        // }
        .ivu-collapse-header {
          height: 60px;
          line-height: 60px;
          font-size: 18px;
          white-space: nowrap;
          display: flex;
          align-items: center;
        }
        .ivu-collapse-content {
          padding: 0;
        }
        .ivu-collapse-content-box {
          padding: 0;
          // background-color: #fcfcfc;
        }
      }
    }

    .header {
      flex: 1;
      display: flex;
      align-items: center;
      .status {
        flex: 0 0 12px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .h-title {
        font-size: 14px;
        color: $white;
      }
      .count {
        flex: 1;
        font-size: 12px;
        color: $white;
        text-align: right;
        padding-right: 32px;
      }
    }
    .TeamChatbottom {
      width: 2.44rem;
      height: 40px;
      margin: 22px auto 15px;
      cursor: pointer;
      background-color: $xf_hui5;
      text-align: center;
      font-size: 13px;
      color: $font_color_1;
      line-height: 40px;
    }
    .borderRadius {
      border-radius: 20px;
      background-color: rgba(70, 70, 70, 0.7);
    }
    .TeamChatbottom:hover {
      background-color: $xf_hui5_hover;
    }
    .TeamChatbottom:hover {
      background-color: $xf_hui5_hover;
    }
  }
  .lists::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }
  .lists::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }
}
.ivu-collapse {
  border-radius: 0;
  border: none;
}
/deep/.ivu-collapse > .ivu-collapse-item > .ivu-collapse-header {
  border-bottom: none;
}
/deep/.ivu-collapse > .ivu-collapse-item {
  border-top: none;
}
/deep/.PilotPlay-modal {
  .header {
    position: relative;
    span {
      display: block;
      font-size: 16px;
      width: 100%;
      text-align: center;
    }
    i {
      position: absolute;
      left: 10px;
      top: 50%;
      font-size: 24px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  .content {
    width: 100%;
    height: 6rem;
    display: flex;
    overflow: hidden;
    border-radius: 0.1rem;
    .list {
      padding: 0.1rem 0;
      width: 20%;
      height: 100%;
      overflow: auto;
      p {
        text-align: center;
        margin-top: 0.4rem;
        font-size: 0.16rem;
        color: #d0d0d0;
      }
      .select {
        border: 1px solid #57a3f3 !important;
      }
      .item {
        width: 2rem;
        height: 0.6rem;
        border-radius: 0.05rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 0.06rem;
        padding: 0 0.06rem;
        cursor: pointer;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        border: 1px solid $white;
        .head_portrait {
          height: 0.4rem;
          width: 0.4rem;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
          }
        }
        span {
          width: 1rem;
          font-size: 0.14rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        i {
          font-size: 0.2rem;
        }
      }
    }
    .list::-webkit-scrollbar {
      width: 0;
    }
    .videoBox {
      width: 80%;
      height: 100%;
      background-color: #414141;
    }
  }
}
</style>
