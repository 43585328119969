<template>
  <div ref="folderModal"
       class="folder-modal"
       :class="dynamicClass"
       :style="dynamicStyle">
    <div class="inner">
      <div class="btn"
           @click="triggle()">
        <Icon size="24"
              type="ios-arrow-forward" />
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'rightFolderModal',
  props: {
    config: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  created() {
    let defaultConfig = {
      // 是否展开  true / false
      show: true,
      // 宽度
      width: 3.4,
      // 背景色
      backgroundColor: '#707070',
      // 位置 left / right
      direction: 'right',
    };
    this.$set(
      this,
      'userConfig',
      _.merge({}, defaultConfig, this.config || {})
    );
  },
  computed: {
    // 悬浮框 可以左右设置
    dynamicClass: function () {
      if (this.userConfig.show && this.userConfig.direction === 'left') {
        return 'left';
      }
      if (this.userConfig.show && this.userConfig.direction === 'right') {
        return 'right';
      }
      if (!this.userConfig.show && this.userConfig.direction === 'left') {
        return 'left hidden-left';
      }
      if (!this.userConfig.show && this.userConfig.direction === 'right') {
        return 'right hidden-right';
      }
    },
    dynamicStyle: function () {
      console.log('11', this.userConfig.width);
      return {
        width: this.userConfig.width + 'rem',
        backgroundColor: this.userConfig.backgroundColor,
      };
    },
  },
  data() {
    return {
      userConfig: {},
    };
  },
  methods: {
    triggle() {
      // 在切换展示的时候有些按钮需要跟随一起移动
      this.userConfig.show = !this.userConfig.show;
      this.$refs['folderModal'].style[this.userConfig.direction] = this
        .userConfig.show
        ? '0'
        : '-' + (this.width || this.$refs['folderModal'].style.width);
      this.$emit('toggleFolder');
    },
  },
};
</script>

<style lang="scss" scoped>
.right {
  right: 0;
  .btn {
    left: -30px;
  }
}
.left {
  left: 0;
  border-radius: 0;
  .btn {
    right: -30px;
    i {
      transform: rotate(180deg);
    }
  }
}
.folder-modal {
  transition: all 0.3s ease-in;
  position: absolute;
  top: 0;
  height: 100%;
  background-color: $xf_hui1;
  color: $white;
  .inner {
    background: $xf_hui1;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .btn {
    position: absolute;
    top: 39px;
    background-color: $xf_hui1;
    border-radius: 4px 0 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 30px;
    height: 76px;
    cursor: pointer;
    line-height: 78px;
    i {
      transition: all 0.3s ease-in;
      color: $white;
    }
  }
}
.hidden-right {
  transition: all 0.3s ease-in;
  .btn {
    i {
      transform: rotate(180deg);
      transition: all 0.3s ease-in;
    }
  }
}
.hidden-left {
  transition: all 0.3s ease-in;
  .btn {
    i {
      transform: rotate(0deg);
      transition: all 0.3s ease-in;
    }
  }
}
</style>
